<template>
  <div :class="$style.container">
    <router-link to="offers/create-offer" :class="$style.create">
      <Icon name="plus" :class="$style.plus" /> Создать акцию
    </router-link>
    <ul :class="$style.filter">
      <li
        :class="[$style.item, { [$style.active]: 0 === activeFilters }]"
        @click="
          getOffers()
          activeFilters = 0
        "
      >
        Текущие
      </li>
      <li
        :class="[$style.item, { [$style.active]: 1 === activeFilters }]"
        @click="
          getOffers(true)
          activeFilters = 1
        "
      >
        Прошедшие
      </li>
      <li
        :class="[$style.item, { [$style.active]: 2 === activeFilters }]"
        @click="
          getAllOffers()
          activeFilters = 2
        "
      >
        Все
      </li>
    </ul>
    <vue-good-table
      :class="$style.table"
      :columns="columns"
      :rows="offersList"
      styleClass="vgt-table striped"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'beginningAt'">
          {{ fomateDateRow(props.row.beginningAt) }}
        </span>
        <span v-else-if="props.column.field == 'endAt'">
          {{ fomateDateRow(props.row.endAt) }}
        </span>
        <span v-else-if="props.column.field == 'action'">
          <router-link :to="`offers/edit-offer/${props.row.id}`">
            <Icon name="plus" :class="$style.plus" />
          </router-link>
        </span>
        <span
          v-else-if="props.column.field == 'delete'"
          title="Удалить акцию"
          @click="deleteOffer(props.row)"
        >
          <Icon name="plus" :class="$style.delete" />
        </span>
      </template>
    </vue-good-table>
    <Loader :isLoading="isLoading" />
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'
import delivery from '@/delivery'
import { formatDate } from '@/helpers/index.js'
import Loader from '@/components/atoms/LoadingBar.vue'

export default {
  components: { Icon, Loader },
  mounted() {
    this.getOffers()
  },
  data() {
    return {
      columns: [
        {
          label: 'Название акции',
          field: 'title',
          tooltip: 'Название акции',
        },
        {
          label: 'Дата начала',
          field: 'beginningAt',
        },
        {
          label: 'Дата окончания',
          field: 'endAt',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
        {
          label: '',
          field: 'delete',
          sortable: false,
        },
      ],
      activeFilters: 0,
      offersList: [],
      isLoading: false,
    }
  },
  methods: {
    async getOffers(expired = false) {
      this.isLoading = true
      const { value, error } = await delivery.AddwineCore.OffersActions.getList(
        expired,
      )
      if (error) return
      this.offersList = value
      this.isLoading = false
    },
    async getAllOffers() {
      this.isLoading = true
      const { value, error } = await delivery.AddwineCore.OffersActions.getList(
        true,
      )
      if (error) return
      this.offersList = value

      const result = await delivery.AddwineCore.OffersActions.getList(false)
      if (result.error) return
      this.offersList.push(...result.value)
      this.isLoading = false
    },
    fomateDateRow(date) {
      return formatDate(date)
    },
    onActive(index) {
      this.activeFilters = index
    },
    async deleteOffer(offer) {
      if (
        confirm(
          `Вы действительно хотите навсегда удалить акцию "${offer.title}"?`,
        )
      ) {
        const result = await delivery.AddwineCore.OffersActions.delete(offer.id)
        if (result.error) {
          alert('Ошибка удаления акции!')
        } else {
          if (this.activeFilters === 0) {
            await this.getOffers()
          } else if (this.activeFilters === 1) {
            await this.getOffers(true)
          } else {
            await this.getAllOffers()
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  .create {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 1px solid $light-gray;
    margin-bottom: 2rem;
    gap: 0.5rem;
    text-decoration: none;

    color: $dark-gray;
    &:hover {
      background: $white;
    }
  }
  .filter {
    display: flex;
    margin-bottom: 2rem;
    padding: 0;
    .item {
      margin-right: 1.5rem;
      color: $light-gray;
      cursor: pointer;
      position: relative;
      list-style-type: none;
      &.active {
        color: $black-gray;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -0.5rem;
          height: 0.25rem;
          width: 100%;
          background: $black-gray;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  h2 {
    padding: 1rem 0;
  }
  .plus {
    fill: $light-gray;
    width: 1rem;
    height: 1rem;
  }
  .delete {
    fill: $light-gray;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    transform: rotate(45deg);
  }
}
</style>
